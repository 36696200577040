import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/inner"
import SEO from "../components/seo"
import CenterInnerContent from "../components/modules/center-inner-content"
import { getImage } from "../../util/functions"

export default ({ data }) => {
  const post = data.wpPost
  // const { edges: posts } = data.allWpPost
  // const blogSettings = data.wp.blogSettings
  const robots = {
    name: `robots`,
    content: `${post.seo.metaRobotsNoindex}, ${post.seo.metaRobotsNofollow}`,
  }
  const meta = [robots]
  const seo = post.seo

  const addToMeta = (name, content) => {
    if (content) meta.push({ property: name, content: content })
  }

  addToMeta("og:site_name", seo.opengraphSiteName)
  addToMeta(
    "og:image",
    seo.opengraphImage?.localFile?.publicURL
      ? getImage(seo.opengraphImage.localFile.publicURL, true, false)
      : null
  )
  addToMeta("article:publisher", seo.opengraphPublisher)
  addToMeta("article:modified_time", seo.opengraphModifiedTime)
  addToMeta("article:published_time", seo.opengraphPublishedTime)
  addToMeta("og:type", seo.opengraphType)

  const getFeaturedImage = featuredImage => {
    if (!featuredImage) return ""
    else
      return `<img class="featured-image" src="${getImage(
        featuredImage.node.localFile.publicURL,
        true
      )}" alt="${featuredImage.node.altText}"/>`
  }
  return (
    <Layout>
      <SEO
        title={post.seo.title}
        description={post.seo.metaDesc}
        meta={meta}
        schema={post.seo.schema?.raw}
      />
      <CenterInnerContent spacing blog>
        {`<h1>${post.title}</h1>
        ${getFeaturedImage(post.featuredImage)}${post.content}`}
      </CenterInnerContent>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      date
      content
      featuredImage {
        node {
          localFile {
            publicURL
          }
          altText
        }
      }
      seo {
        ...SEO
        ...PostSEO
      }
    }
  }
`
